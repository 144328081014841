import React from 'react'
import ReactDOM from 'react-dom/client'
import DashApp from './DashApp'
import ErrorBoundary from '../ErrorBoundary'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <DashApp />
    </ErrorBoundary>
  </React.StrictMode>,
)
